import React from 'react';
import { FILLED_BUTTON_COLOR } from '../../utils/constants';

interface IButtonWithIconProps {
  linkPath: string;
  iconName: string;
  buttonText: string;
  buttonSize?: string;
  buttonColor?: string;
}

const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({
  linkPath,
  iconName,
  buttonText,
  buttonSize = '',
  buttonColor = FILLED_BUTTON_COLOR['blue'],
}) => {
  return (
    <a href={linkPath} className='nav-link'>
      <button
        type='button'
        className={`btn btn-block ${buttonSize} ${buttonColor}`}
      >
        <i className={iconName}></i> {buttonText}
      </button>
    </a>
  );
};

export default ButtonWithIcon;
