import React from 'react';
import Button from './Buttons/Button';
import {
  BORDER_BUTTON_COLOR,
  BUTTON_SIZE,
  FILLED_BUTTON_COLOR,
} from '../utils/constants';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logout } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const NavBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  function handleDisconnect() {
    dispatch(logout());
  }
  return (
    <div>
      {/* Navbar */}
      <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
        {/* Left navbar links */}
        <ul className='navbar-nav'>
          <li className='nav-item'>
            <a
              className='nav-link'
              data-widget='pushmenu'
              href='/'
              role='button'
            >
              <i className='fas fa-bars' />
            </a>
          </li>
          <li className='nav-item d-none d-sm-inline-block'>
            <a href='index3.html' className='nav-link'>
              Home
            </a>
          </li>
          <li className='nav-item d-none d-sm-inline-block'>
            <a href='/' className='nav-link'>
              Contact
            </a>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className='navbar-nav ml-auto'>
          {/* Navbar Search */}
          {/* Notifications Dropdown Menu */}
          <li className='nav-item dropdown'>
            <a className='nav-link' data-toggle='dropdown' href='/'>
              <i className='far fa-bell' />
              <span className='badge badge-warning navbar-badge'>15</span>
            </a>
            <div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
              <span className='dropdown-item dropdown-header'>
                15 Notifications
              </span>
              <div className='dropdown-divider' />
              <a href='/' className='dropdown-item'>
                <i className='fas fa-envelope mr-2' /> 4 new messages
                <span className='float-right text-muted text-sm'>3 mins</span>
              </a>
              <div className='dropdown-divider' />
              <a href='/' className='dropdown-item'>
                <i className='fas fa-users mr-2' /> 8 friend requests
                <span className='float-right text-muted text-sm'>12 hours</span>
              </a>
              <div className='dropdown-divider' />
              <a href='/' className='dropdown-item'>
                <i className='fas fa-file mr-2' /> 3 new reports
                <span className='float-right text-muted text-sm'>2 days</span>
              </a>
              <div className='dropdown-divider' />
              <a href='/' className='dropdown-item dropdown-footer'>
                See All Notifications
              </a>
            </div>
          </li>
          {isAuthenticated ? (
            <Button
              buttonText='Disconnect'
              buttonColor={BORDER_BUTTON_COLOR['red']}
              buttonSize={BUTTON_SIZE['small']}
              actionFunc={handleDisconnect}
            />
          ) : (
            <Button
              buttonText='Login'
              linkPath='/login'
              buttonColor={FILLED_BUTTON_COLOR['blue']}
              buttonSize={BUTTON_SIZE['small']}
            />
          )}
        </ul>
      </nav>
      {/* /.navbar */}
    </div>
  );
};

export default NavBar;
