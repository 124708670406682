import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../assets/img/OnePrev_icon.webp';
import default_profile_pic from '../assets/img/default_profile_pic.webp';

const SideNav: React.FC = () => {
  return (
    <div>
      {' '}
      {/* Main Sidebar Container */}
      <aside className='main-sidebar sidebar-dark-primary elevation-4'>
        {/* Brand Logo */}
        <a href='/' className='brand-link'>
          <img
            src={logo}
            alt='OnePrev Logo'
            className='brand-image elevation-0'
            style={{ opacity: '.8' }}
          />
          <span className='brand-text font-weight-light'>One Prev</span>
        </a>
        {/* Sidebar */}
        <div className='sidebar'>
          {/* Sidebar user panel (optional) */}
          <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
            <div className='image'>
              <img
                src={default_profile_pic}
                className='img-circle elevation-2'
                alt='User'
              />
            </div>
            <div className='info'>
              <a href='/' className='d-block'>
                Admin
              </a>
            </div>
          </div>
          {/* SidebarSearch Form */}
          <div className='form-inline'>
            <div className='input-group' data-widget='sidebar-search'>
              <input
                className='form-control form-control-sidebar'
                type='search'
                placeholder='Search'
                aria-label='Search'
              />
              <div className='input-group-append'>
                <button className='btn btn-sidebar'>
                  <i className='fas fa-search fa-fw' />
                </button>
              </div>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className='mt-2'>
            <ul
              className='nav nav-pills nav-sidebar flex-column'
              data-widget='treeview'
              role='menu'
              data-accordion='false'
            >
              {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
              <li className='nav-header'>PRINCIPALES</li>
              <li className='nav-item'>
                <NavLink to='/' className='nav-link'>
                  <i className='nav-icon fas fa-tachometer-alt' />
                  <p>Dashboard</p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/admin/questions' className='nav-link'>
                  <i className='nav-icon fas fa-question' />
                  <p>Questions</p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/admin/quizz' className='nav-link'>
                  <i className='nav-icon fas fa-list' />
                  <p>Quizz</p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/admin/users' className='nav-link'>
                  <i className='nav-icon fas fa-users' />
                  <p>Users</p>
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
};

export default SideNav;
