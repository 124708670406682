import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { IQuestionsData } from '../../types/Questions';
import Table from '../../components/Tables/Table';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon';
import { BUTTON_SIZE } from '../../utils/constants';
import { axiosPrivateWithToken } from '../../api/axios';
import { useAppSelector } from '../../app/hooks';

const Questions = () => {
  const { isAuthenticated, token } = useAppSelector((state) => state.auth);

  const [questionsData, setQuestionsData] = useState<IQuestionsData[]>([]);

  const getQuestions = async () => {
    const axiosFileInstance = axiosPrivateWithToken(token);

    console.log(token);

    try {
      const response = await axiosFileInstance.get('/api/questions');

      console.log('Réponse du serveur:', response.data);
      setQuestionsData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) getQuestions();
  }, [isAuthenticated, token]);

  useEffect(() => {
    document.title = 'Questions';
  }, []);

  return (
    <div className='content-wrapper'>
      {/* Content Header (Page header) */}
      <section className='content-header'>
        <div className='container-fluid'>
          <div className='row mb-2'>
            <div className='col-sm-6'>
              <h1>Questions</h1>
            </div>
            <div className='col-sm-6'>
              <ol className='breadcrumb float-sm-right'>
                <ButtonWithIcon
                  linkPath='/admin/questions/import_questions'
                  iconName='fa fa-plus'
                  buttonText='Add questions'
                  buttonSize={BUTTON_SIZE['large']}
                />
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title'>
                    All questions in OnePrev's database
                  </h3>
                </div>
                {/* /.card-header */}
                <div className='card-body'>
                  <Table data={questionsData} />
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  );
};

export default Questions;
