import React from 'react';
import { FILLED_BUTTON_COLOR } from '../../utils/constants';
import { Link } from 'react-router-dom';

interface IButtonnProps {
  linkPath?: string;
  buttonText: string;
  buttonSize?: string;
  buttonColor?: string;
  actionFunc?: (...args: any) => any;
}

const Button: React.FC<IButtonnProps> = ({
  linkPath = '',
  buttonText,
  buttonSize = '',
  buttonColor = FILLED_BUTTON_COLOR['blue'],
  actionFunc,
}) => {
  return (
    <>
      {linkPath && (
        <Link to={linkPath} className='nav-link'>
          <button
            type='button'
            className={`btn btn-block ${buttonSize} ${buttonColor}`}
            onClick={(e) => e}
          >
            {buttonText}
          </button>
        </Link>
      )}
      {!linkPath && (
        <button
          type='button'
          className={`btn btn-block ${buttonSize} ${buttonColor}`}
          onClick={actionFunc}
        >
          {buttonText}
        </button>
      )}
    </>
  );
};

export default Button;
