import React, { useEffect, useState } from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';

import './App.css';
import NavBar from './components/NavBar';
import SideNav from './components/SideNav';
import Footer from './components/Footer';
import Home from './pages/Home';
import Questions from './pages/Questions/Questions';
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import Quizz from './pages/Quizz';
import Users from './pages/Users/Users';
import ImportQuestions from './pages/Questions/ImportQuestions';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setUser } from './features/auth/authSlice';

function WithNav() {
  return (
    <>
      <NavBar />
      <SideNav />
      <Outlet />
      <Footer />
    </>
  );
}

function WithoutNav() {
  return <Outlet />;
}

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isError, isSuccess, isAuthenticated } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(setUser());
    } else {
      navigate('/login');
    }
  }, [isError, isSuccess, isAuthenticated]);

  return (
    <Routes>
      <Route element={<WithoutNav />}>
        <Route path='login' element={<Login />}></Route>
        <Route path='*' element={<NoMatch />}></Route>
      </Route>
      <Route element={<WithNav />}>
        <Route index element={<Home />}></Route>{' '}
        <Route path='admin'>
          <Route index element={<Home />}></Route>
          <Route path='questions'>
            <Route index element={<Questions />}></Route>
            <Route
              path='import_questions'
              element={<ImportQuestions />}
            ></Route>
          </Route>
          <Route path='quizz' element={<Quizz />}></Route>
          <Route path='users' element={<Users />}></Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
