import React, { useState } from 'react';
import {
  axiosPrivateWithToken,
  axiosPrivateFileWithToken,
} from '../../api/axios';
import { useAppSelector } from '../../app/hooks';

export default function FileUpload() {
  const { token } = useAppSelector((state) => state.auth);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const axiosFileInstance = axiosPrivateFileWithToken(token);

      console.log(selectedFile);

      try {
        const response = await axiosFileInstance.post(
          '/api/questions/import_by_file',
          formData
        );

        // Vous pouvez gérer la réponse ici
        console.log('Réponse du serveur:', response);
      } catch (error: any) {
        console.error(error.response);
      }
    }
  };

  return (
    <div className='content-wrapper'>
      <section className='content-header'>
        <div className='container-fluid'>
          <div className='row mb-2'>
            <div className='col-sm-6'>
              <h1>Add questions</h1>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className='content'>
        <div className='container-fluid'>
          <div className='card'>
            <div className='card-header'>Import questions from file</div>
            <div className='card-body'>
              <input type='file' onChange={(e) => handleFileChange(e)} />
              <button onClick={handleFileUpload}>Envoyer le fichier</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
