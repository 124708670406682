export const FILLED_BUTTON_COLOR = {
  blue: 'btn-primary',
  gray: 'btn-secondary',
  green: 'btn-success',
  navyBlue: 'btn-info',
  red: 'btn-danger',
  yellow: 'btn-warning',
  white: 'btn-white',
  black: 'btn-black',
};

export const BORDER_BUTTON_COLOR = {
  blue: 'btn-outline-primary',
  gray: 'btn-outline-secondary',
  green: 'btn-outline-success',
  navyBlue: 'btn-outline-info',
  red: 'btn-outline-danger',
  yellow: 'btn-outline-warning',
  white: 'btn-outline-white',
  black: 'btn-outline-black',
};

export const BUTTON_SIZE = {
  large: 'btn-lg',
  small: 'btn-sm',
  extraSmall: 'btn-xs',
  flat: 'btn-flat',
  disabled: 'disabled',
};
