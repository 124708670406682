import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { login } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const dispatch = useAppDispatch();
  const { isError, isSuccess, message, isAuthenticated } = useAppSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  function handleEmailInput(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
    console.log(email);
  }

  function handlePasswordInput(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  function handleSumbit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const userData = {
      email,
      password,
    };
    dispatch(login(userData));
  }

  useEffect(() => {
    if (isError && message) {
      console.log(message);
    } else if (isSuccess && isAuthenticated) {
      navigate('/');
      console.log('auth successfully');
    }
  }, [message, isSuccess, isError, isAuthenticated]);

  return (
    <div className='hold-transition login-page'>
      <div className='login-box'>
        {/* /.login-logo */}
        <div className='card card-outline card-primary'>
          <div className='card-header text-center'>
            <a href='../../index2.html' className='h1'>
              <b>One</b>Prev
            </a>
          </div>
          <div className='card-body'>
            <p className='login-box-msg'>Connexion</p>
            <form method='post' onSubmit={(e) => handleSumbit(e)}>
              <div className='input-group mb-3'>
                <input
                  type='email'
                  id='email'
                  className='form-control'
                  placeholder='Email'
                  onChange={(e) => handleEmailInput(e)}
                />
                <div className='input-group-append'>
                  <div className='input-group-text'>
                    <span className='fas fa-envelope' />
                  </div>
                </div>
              </div>
              <div className='input-group mb-3'>
                <input
                  type='password'
                  id='password'
                  className='form-control'
                  placeholder='Password'
                  onChange={(e) => handlePasswordInput(e)}
                />
                <div className='input-group-append'>
                  <div className='input-group-text'>
                    <span className='fas fa-lock' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-7'>
                  <div className='icheck-primary'>
                    <input type='checkbox' id='remember' />
                    <label htmlFor='remember'>Se souvenir de moi</label>
                  </div>
                </div>
                {/* /.col */}
                <div className='col-5'>
                  <button type='submit' className='btn btn-primary btn-block'>
                    Se connecter
                  </button>
                </div>
                {/* /.col */}
              </div>
            </form>
            {/* /.social-auth-links */}
            <p className='mb-1'>
              <a href='/'>Mot de passe oublié ?</a>
            </p>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </div>
    </div>
  );
};

export default Login;
