import React, { useMemo } from 'react';
import { IQuestionsData } from '../../types/Questions';
import { firstLetterUpper } from '../../utils/textFormatter';
import { IUserData } from '../../types/Users';

interface ITableProps {
  data: IQuestionsData[] | IUserData[];
}

const Table: React.FC<ITableProps> = ({ data }) => {
  const headerNames = useMemo(() => {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]);
  }, [data]);

  if (data.length === 0) {
    return null;
  }

  return (
    <table id='example2' className='table table-bordered table-hover'>
      <thead>
        <tr>
          {headerNames.map((header, index) => (
            <th key={index}>{firstLetterUpper(header)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {headerNames.map((header, colIndex) => (
              <td key={colIndex}>
                {header === 'createdAt' || header === 'lastLogin'
                  ? (row as any)[header].date
                  : (row as any)[header]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
